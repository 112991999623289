import React, {useEffect, useState} from 'react';
import { ChangeSubscriptionPlans } from '../ChangeSubscriptionPlans';
import {BaseModal} from './index';
import {updateSubscription} from "../../../lib/api";
import {getSessionId} from "../../../lib/storage";
import tr from '../../../../translations';
const { PRICE, CURRENCY } = require('../../../../config');


const ChangeSubscriptionModal = ({current, show, onClose}) => {

  const [type, setType] = useState('0');
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    setType(current.subscriptionType === 'YEARLY' ? '12' : '1');
  }, [current]);

  const handleSave = async (evt) => {
    evt.preventDefault();

    try {
      setWaiting(true);

      const data = {
        "periodCount": type,
        "periodUnit": "months"
      }
      await updateSubscription(getSessionId(), data)

      onClose(true);
    } catch (ex) {
      console.error(ex)
      alert(ex.message);
    } finally {
      setWaiting(false);
      alert(tr('changeSub.updatedSub', type));
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  let regex = new RegExp(`([\\d,\\.]+)\\s*${CURRENCY}`);
  let currentPrice = current.price ? (current.price).match(regex)[1] : null;
  let percentSaving;

  if (currentPrice && PRICE.MONTHLY && PRICE.YEARLY) {
      parseFloat(currentPrice[1].replace(',', '.'));
      percentSaving = current.subscriptionType === 'MONTHLY' ? 
        Math.floor((currentPrice * 12 - parseInt(PRICE.YEARLY))/(currentPrice*12) * 100) :
        Math.floor(((parseFloat(PRICE.MONTHLY.replace(',', '.')) * 12 - currentPrice) / (parseFloat(PRICE.MONTHLY.replace(',', '.')) * 12)) * 100);
  } else {
    percentSaving = null;
  }

  return (
    <BaseModal
      title={tr('profile.subscription.changeSub')}
      show={show}
      waiting={waiting}
      disabled={type === (current.subscriptionType === 'YEARLY' ? '12' : '1')}
      onClose={handleClose}
      onSave={handleSave}
    >
      <ChangeSubscriptionPlans styling={"change-subscription grey"} type={type} setType={setType} currentType={current.subscriptionType} percentSaving={percentSaving}/>

    </BaseModal>
  );
};

export default ChangeSubscriptionModal;