module.exports = {
  'siteMetadata.title': 'Lylli | Tusentals e-böcker och ljudböcker för barn',
  'siteMetadata.description':
    'Lylli är appen som väcker barnens läslust i en digital värld. Tusentals fängslande böcker med röster, ljud och animationer för en ännu roligare läsupplevelse.',
  'head.title': 'Lylli | Tusentals e-böcker och ljudböcker för barn',

  'link.klarna': 'https://www.klarna.com/se/klarna-app/',
  'link.inviteFriend': 'https://www.lylli.se/kompis/?code=',
  'link.faq': 'https://www.lylli.se/vanliga-fragor',
  'link.createAccount': 'https://www.lylli.se/konto/skapa-konto',

  logout: 'Logga ut',

  'login.title': 'Logga in',
  'login.email-label': 'E-post',
  'login.email-placeholder': 'Fyll i din e-post',
  'login.password-label': 'Ditt lösenord',
  'login.password-placeholder': 'Lösenord',
  'login.submit': 'Logga in',
  'login.submit-error': 'Felaktigt e-postadress eller lösenord.',
  'login.createAccount': 'Skapa konto',
  'login.resetPassword': 'Glömt lösenord?',
  'login.loading': 'Loggar in...',

  'resetPassword.title': 'Glömt lösenord',
  'resetPassword.text': 'För att återställa ditt lösenord, fyll i din e-post.',
  'resetPassword.email-placeholder': 'E-post',
  'resetPassword.submit': 'Återställ lösenord',
  'resetPassword.submit-confirmed': 'En återställningslänk har skickats till din e-post.',

  'password.title': 'Återställ lösenord',
  'password.text': 'Du är på väg att byta ditt lösenord.',
  'password.password-label': 'Välj nytt lösenord',
  'password.repeatPassword-label': 'Upprepa lösenord',
  'password.submit': 'Byt lösenord',
  'password.submit-confirmed': 'Ditt lösenord är nu ändrat.',
  'password.submit-errorLength': 'Lösenordet måste vara minst 8 tecken.',
  'password.submit-errorRepeat': 'Lösenorden matchar inte varandra.',

  'shareCode.notFound':
    'Koden kunde inte hämtas, vänligen försök igen eller kontakta kontakt@lylli.se',

  'profile.title': 'Din profil',
  'profile.welcome-header': 'Hejsan!',
  'profile.welcome-subheader': 'Mina sidor',
  'profile.changeBtn': 'Ändra',

  'profile.subscription-header': 'Prenumeration',
  'profile.subscription.type-header': 'Prenumerationstyp:',
  'profile.subscription.type-inactive': 'Inaktiv',
  'profile.subscription.type-notActivated': 'Ej aktiverad',
  'profile.subscription.type-golden': 'Gratis provperiod utan registrerad betalmetod',
  'profile.subscription.type-giftcard': 'Presentkort utan registrerad betalmetod',

  'profile.subscription.startSub': 'Starta prenumeration',
  'profile.subscription.endSub': (type) => {
    if (type === 'APPLE') {
      return 'Avsluta prenumeration via Apple';
    } else {
      return 'Avsluta prenumeration';
    }
  },
  'profile.subscription.changeSub': (type) => {
    if (type === 'APPLE') {
      return 'Hantera prenumeration via Apple';
    } else {
      return 'Byt prenumeration';
    }
  },
  'profile.subscription.startTrial': 'Starta provperiod',
  'profile.subscription.addPayment': 'Lägg till betalmetod',
  'profile.subscription.loading': 'Laddar prenumerationsinfo...',
  'profile.subscription.active-offer-header': 'Aktuellt erbjudande:',
  'profile.subscription.active-offer-text': (discount, totalPeriods, subscriptionType) => {
    const plural = totalPeriods !== 1;
    let periodText;
    let periodicity;

    if (totalPeriods === 1 && (subscriptionType === 'YEARLY' || subscriptionType === 'QUARTERLY')) periodText = 'i ett';
    else if (totalPeriods === 1 && subscriptionType === 'MONTHLY') periodText = 'i en';
    else if (totalPeriods === 2) periodText = 'i två';
    else periodText = `i ${totalPeriods}`;

    if (subscriptionType === 'MONTHLY') periodicity = 'månad' + (plural ? 'er' : '');
    else if (subscriptionType === 'YEARLY') periodicity = 'år';
    else if (subscriptionType === 'QUARTERLY') periodicity = 'kvartal';
    else periodicity = subscriptionType;

    return `${discount} rabatt ${periodText} ${periodicity}`;
  },
  'profile.subscription.offer-header': 'Erbjudande:',
  'profile.subscription.offer-firstMonth': 'första ',
  'profile.subscription.addPayment-reactivate':
    'Lägg till en betalmetod och återaktivera ditt konto för',
  'profile.subscription.addPayment-freeTrial': {
    text1: 'Lägg till en betalmetod och läs med Lylli gratis i ',
    text2: ' dagar',
  },
  'profile.subscription.activeDays-header': 'Fortsätt använda Lylli i:',
  'profile.subscription.nextPayment-header': 'Nästa betalning:',
  'profile.subscription.trialPeriod-header': 'Provperiod:',
  'profile.subscription.trialPeriod-days': (days) =>
    days === 1 ? `${days} dag kvar` : `${days} dagar kvar`,
  'profile.subscription.grace-addPayment':
    'Vi har inte lyckats debitera dig för din prenumeration. Uppdatera din betalmetod för att fortsätta läsa med Lylli.',
  'profile.subscription.golden-addPayment': (date) => `Registrera en betalmetod senast ${date} för att fortsätta läsa med Lylli.`,
  'profile.subscription.giftcard-addPayment': (date) => `Registrera en betalmetod senast ${date} för att fortsätta läsa med Lylli efter att presentkortet har gått ut.`,
  'profile.subscription.validUntil-header': 'Gäller till:',
  
  'cancelSub-title': (step, periodCount, price, subscriptionType) => {
    if (step === 1) return 'Innan du avslutar...';
    if (step === 2) return 'Säker på att du vill avsluta?';
    if (step === 3) {
      if(periodCount && price && subscriptionType) {
        const plural = periodCount !== 1;
        let periodicity;
        if (subscriptionType === 'MONTHLY') periodicity = 'månade' + (plural ? 'rna' : 'n');
        else if (subscriptionType === 'YEARLY') periodicity = 'åre' + (plural ? 'n' : 't');
        else periodicity = subscriptionType;    

        if(subscriptionType === 'MONTHLY') return `Erbjudande: ${periodCount} ${periodicity} för ${price} kr`;
        if(subscriptionType === 'YEARLY') return '';
      } else {
        return '';
      }
    };
    if (step === 4) return 'Varför vill du säga upp din prenumeration?';
    if (step === 5) return 'Din prenumeration är nu avslutad 😢';
  },

  'cancelSub-subtitle': (step) => {
    if (step === 1) return null;
    if (step === 2) return null;
    if (step === 3) return 'Sista chansen';
    if (step === 4) return 'Avsluta prenumeration';
    if (step === 5) return null;
  },

  'cancelSub-secondaryCTA': (step) => {
    if (step === 1) return 'Avsluta prenumeration';
    if (step === 2) return 'Avsluta ändå';
    if (step === 3) return 'Nej, tack';
    if (step === 4) return 'Stanna kvar';
    if (step === 5) return 'Skicka feedback';
  },

  'cancelSub-primaryCTA': (step) => {
    if (step === 1) return 'Stanna kvar & skicka till support';
    if (step === 2) return 'Behåll mitt pris';
    if (step === 3) return 'Acceptera erbjudande';
    if (step === 4) return 'Avsluta prenumeration';
    if (step === 5) return 'Stäng';
  },

  'cancelSub.subtitle-cancellationOffer': (periodCount, discountPrice) => {
    return `Erbjudande: ${periodCount} månad för ${discountPrice} kr`
  },

  'cancelSub.contactSupport': {
    text1: 'Är det något du har svårt att hitta eller som inte fungerar?',
    text2: 'Beskriv ditt problem i fältet nedan så återkommer vi inom kort. Kanske vi kan få dig att stanna kvar hos oss?'
  },

  'cancelSub.contactSupport-CTA': 'Skriv ditt meddelande i fältet ovan så återkommer vi!',

  'cancelSub.currentDiscount-foreverDiscount': (subscriptionType, currentPrice, defaultPrice) => {
    if(subscriptionType === 'MONTHLY') return `Just nu har du ett pris på ${currentPrice} kr/månad – hela ${defaultPrice - currentPrice} kr mindre än vårt nuvarande månadspris på ${defaultPrice} kr.`;
    if (subscriptionType === 'YEARLY') {
      const monthlyPrice = (currentPrice / 12).toFixed();
      return `Just nu har du ett pris på ${monthlyPrice} kr/månad (${currentPrice} kr/år) – hela ${defaultPrice - currentPrice} kr mindre än vårt nuvarande årspris på ${defaultPrice} kr.`;
    } 
  },

  'cancelSub.currentDiscount-nextPaymentDiscount': (subscriptionType, currentPrice, defaultPrice) => {
    if(subscriptionType === 'MONTHLY') return `Just nu betalar du bara ${currentPrice} kr för kommande månad (ordinarie pris ${defaultPrice} kr/månad).`;
    if(subscriptionType === 'YEARLY') return `Just nu betalar du bara ${currentPrice} kr för kommande år (ordinarie pris ${defaultPrice} kr/år).`; 
  },

  'cancelSub.currentDiscount-percentageOf': (percentage, subscriptionType) => {
    if(subscriptionType === 'MONTHLY') return `Just nu har du ${percentage}% rabatt på din prenumeration i mer än en månad framöver.`
    if(subscriptionType === 'YEARLY') return `Just nu har du ${percentage}% rabatt på din prenumeration i mer än ett år framöver.`
  },

  'cancelSub.currentDiscount-text' : 'Om du avslutar ditt konto kommer du att förlora denna rabatt och kan inte få det tillbaka.',

  'cancelSub.cancellationOffer': (subscriptionType, periodCount, price) => {
    const plural = periodCount !== 1;
    let periodicity;

    if (subscriptionType === 'MONTHLY') periodicity = 'månad' + (plural ? 'er' : '');
    else if (subscriptionType === 'YEARLY') periodicity = 'år';
    else periodicity = subscriptionType;    

    return `Läs och lyssna obegränsat i ytterligare ${periodCount} ${periodicity} för bara ${price} kronor.`
  },

  'cancelSub.cancellationOffer-yearly': (price) => {
    return `Stanna kvar i ett år för ${(price/12).toFixed(2)} kr/månad (${price} kr/år) och få ett gosedjur (värde 299 kr) skickat direkt hem till dig.`
  },

  'cancelSub.cancellationOffer-text': 'Självklart påminner vi dig innan perioden är slut.',
  
  'cancelSub.chooseReason': 'Du behöver välja ett alternativ för att avsluta!',
  

  'cancelSub.endSub-activeUntil': (date) => {
    return `Du kan fortsätta använda Lylli till ${date}.`;
  },
  'cancelSub.feedback': {
    text1: 'Finns det något vi skulle kunna göra bättre?',
    text2: 'Vi är tacksamma för all feedback.',
  },

  'cancelSub.confirmation': {
    text1: 'Hoppas vi ses snart igen ',
    text2: 'Lylligänget',
  },

  'cancelSub.reasons': [
    { id: 'content-not-good-enough', label: 'Böckerna jag vill läsa/lyssna på saknas' },
    { id: 'reduce-costs', label: 'Kostnaden är för hög' },
    { id: 'had-technical-issues', label: 'Jag upplever problem med appen' },
    { id: 'try-other-service', label: 'Jag ska prova en annan tjänst istället ' },
    { id: 'only-wanted-to-test', label: 'Jag ville bara ta del av erbjudandet' },
    { id: 'below-expectations', label: 'Tjänsten motsvarade inte mina förväntningar' },
    { id: 'others-no-reason', label: 'Annan anledning' },
  ],

  'changeSub.updatedSub': (type) => {
    if (type === '1') {
      return 'Nu är din prenumeration uppdaterat till en månadsprenumeration';
    } else {
      return 'Nu är din prenumeration uppdaterat till en årsprenumeration';
    }
  },
  'changeSub.tipCTA-header': 'Tips:',
  'changeSub.tipCTA-text': (percentSaving) => {
    if(percentSaving) return ` byt till en årsprenumeration och spara ${percentSaving}%`;
    return ' byt till en årsprenumeration och spara 28%';
  },
  'changeSub.savings': (percentSaving) => {
    if (percentSaving) return `Spara ${percentSaving}%`;
    return 'Spara 28%';
  },

  'profile.payment-header': 'Betalning',
  'profile.payment.type-header': 'Betalmetod:',
  'profile.payment.type-missing': 'Saknas',
  'profile.payment.type-card': 'Kreditkort',
  'profile.payment.type-apple_pay': 'Apple Pay',
  'profile.payment.type-google_pay': 'Google Pay',
  'profile.payment.type-paypal': 'PayPal',
  'profile.payment.type-invoice': 'Klarna faktura',
  'profile.payment.type-direct_debit': 'Klarna direktbetalning',
  'profile.payment.type-in_app_purchase': 'Apple prenumeration',
  'profile.payment.type-unknown': 'Aktiv',

  'profile.account-header': 'Konto',
  'profile.account.accountSince-header': 'Kund sedan:',
  'profile.account.email-header': 'Mejladress:',
  'profile.account.password-header': 'Lösenord:',

  'profile.payment-history-header': 'Köphistorik',
  'profile.payment-history-subscription': 'Prenumeration',
  'profile.receipts-header': 'Kvitton',
  'profile.receipts-empty': 'Inga kvitton att visa',
  'profile.receipt-header': 'Kvitto',
  'profile.receipt-seller': 'Försäljare: ',
  'profile.receipt-seller-organisation': 'Org nr: ',
  'profile.receipt-seller-adress': 'Adress: ',
  'profile.receipt-date': 'Datum: ',
  'profile.receipt-amount': 'Belopp: ',
  'profile.receipt-subtotal-amount': 'Ex moms: ',
  'profile.receipt-tax-amount': 'Moms: ',
  'profile.receipt-items': 'Artiklar',
  'profile.receipt-item': 'st',
  'profile.receipt-pdf': 'Hämta PDF',
  'profile.receipts-error': 'Kunde inte hämta kvitton',

  'profile.download-header': 'Ladda ner Lylli-appen',
  'profile.download-text':
    'Lylli finns för både iOS- och Android. Appen kan användas på mobil och surfplatta.',

  'profile.help-header': 'Behöver du hjälp?',
  'profile.help.faq': 'FAQ',
  'profile.help.support': 'Support',

  'inviteFriend-header': 'Bjud in en kompis!',
  'inviteFriend-text':
    'Bjud in en vän så får din kompis prova Lylli gratis i 30 dagar! Dela koden nedan.',
  'inviteFriend-textCopied': (copy) => {
    if (copy) return 'Kopierat!';
    return 'Kopiera';
  },

  'apple-step1': '1. Öppna appen Inställningar på den enhet där du skapat prenumerationen.',
  'apple-step2': '2. Tryck på ditt namn.',
  'apple-step3': '3. Tryck på Prenumerationer.',
  'apple-step4': '4. Tryck på Lylli.',

  'apple.cancelSubscription-header': 'Så här går du tillväga för att avsluta din prenumeration.',
  'apple.cancelSubscription-step5': '5. Tryck på Avbryt prenumerationen.',

  'apple.handleSubscription-header': 'Så här går du tillväga för att ändra din prenumeration.',
  'apple.handleSubscription-step5': '5. Välj en ny prenumerationstyp.',

  'apple.changeSubscription-header': 'Du har valt Apple som betalmetod',
  'apple.changeSubscription-text1':
    'För att ändra betalmetod måste du för avsluta din Apple prenumeration och sedan återaktivera din prenumeration här på Mina Sidor med önskad betalmetod.',
  'apple.changeSubscription-text2': 'Avsluta Apple prenumeration:',

  'apple.getSupport': 'Behöver du hjälp? Kontakta support på ',

  'apple.receipt-appstore': 'via App Store',
  'apple.receipt-header': 'Hitta kvitton för köp via App Store',
  'apple.receipt-step3': '3. Tryck på Medier och köp och sedan Visa konto.',
  'apple.receipt-step4': '4. Tryck på Köphistorik och sök på Lylli',

  'receipts.klarna': {
    text1: 'Logga in i ',
    linkText: 'Klarna-appen',
    text2: ' för att se dina kvitton.',
  },

  'receipts.contactSupport': {
    text1: 'Kontakta support på ',
    text2: ' för att få dina kvitton.',
  },

  giftcard: {
    text1: 'Kul att du vill lösa in ditt presentkort! Kontakta support på ',
    text2: ' så hjälper vi dig.',
  },

  'modido.changeCard-header': 'Hantera din betalning',
  'modido.changeCard-text':
    'Här lägger du till det kort som du vill använda för att prenumerera på Lylli.',

  accountUpdated: 'Kontot uppdaterat',

  'subscription.activeInDays': (days) => `${days} dag${days === 1 ? '' : 'ar'}`,

  'base.save': 'Spara',
  'base.close': 'Stäng',

  'changeEmail.title': 'Ändra e-post',
  'changeEmail.confirmPassword-label': 'Fyll i lösenord:',
  'changeEmail.confirmPassword-placeholder': 'Ditt lösenord',
  'changeEmail.email-label': 'Fyll i din nya e-post:',
  'changeEmail.email-placeholder': 'E-post',
  'changeEmail.confirmEmail-label': 'Bekräfta den nya e-posten:',
  'changeEmail.error.incorrectEmail':
    'E-postadressen du skrivit verkar inte vara en korrekt e-post',
  'changeEmail.error.noMatch': 'E-post-adresserna stämmer inte överens',

  'changePassword.title': 'Ändra lösenord',
  'changePassword.oldPassword-label': 'Fyll i nuvarande lösenord:',
  'changePassword.oldPassword-placeholder': 'Ditt gamla lösenord',
  'changePassword.newPassword-label': 'Skriv i ditt nya lösenord:',
  'changePassword.newPassword-placeholder': 'Ditt nya lösenord',
  'changePassword.confirmPassword-label': 'Bekräfta det nya lösenordet:',
  'changePassword.confirmPassword-placeholder': 'Ditt nya lösenord igen',
  'changePassword.error.oldPassword': 'Du måste fylla i ditt gamla lösenord',
  'changePassword.error.tooShort': 'Se till att ditt lösenord är minst 8 tecken långt',
  'changePassword.error.noMatch': 'Lösenorden stämmer inte överens',

  'date-format': 'sv-SE',

  'loginApp.title': 'Logga in med QR',
  'loginApp.text-bold': 'Är Lylli-appen nedladdad?',
  'loginApp.text': {
    text1: 'Klicka på ',
    linkText: 'länken ',
    text2: 'eller skanna koden med kameran i din läsplatta eller smartphone för snabb inloggning 👉'
  }
};
